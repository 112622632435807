import {ResultasticFeature, toEuro} from "@spoferan/spoferan-ts-core";

export function useResultasticFeaturesInfoPage() {

    const {resultasticStore} = useResultasticInfoPage();

    const featureValues = ResultasticFeature;
    const features = resultasticStore.features;

    const getFeatureFeePerParticipantInEuro = (featureValue: string) => {
        const feature = features.find((feature: any) => feature.value === featureValue);

        return feature ? toEuro(feature.fee_per_participant) : '-';
    }

    return {
        getFeatureFeePerParticipantInEuro,
        resultasticStore,
        features,
        featureValues
    }
}